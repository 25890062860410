import React, { ReactText } from 'react';
import Banner, { BannerType } from './Banner';

export interface Banner {
    id: ReactText;
    text: string;
    type: BannerType;
    closeable?: boolean;
    dark?: boolean;
}

export interface BannersProps {
    banners: Banner[];
    removeBanner: (id: ReactText) => void;
}

const Banners = ({ banners, removeBanner }: BannersProps) => (
    <div className="banners">
        {banners.map(x => <Banner key={x.id} {...x} onDisappear={x.closeable ? () => removeBanner(x.id) : undefined} />)}
    </div>
);

export default Banners;
