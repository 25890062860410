import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Banner } from '../components/common/Banners';
import { ReactText } from 'react';

export interface BannerState {
  banners: Banner[];
}

const initialState: BannerState = {
  banners: []
};

export const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    addBanner: (state: BannerState, action: PayloadAction<Banner>) => {
      state.banners = [...state.banners, action.payload]
    },
    removeBanner: (state: BannerState, action: PayloadAction<ReactText>) => {
      state.banners = state.banners.filter(b => b.id !== action.payload);
    }
  }
});

export const { addBanner, removeBanner } = bannerSlice.actions;
export const bannerReducer = bannerSlice.reducer;