import React, { CSSProperties, ReactNode } from 'react';

export enum FlexDirection {
    Column,
    ColumnReverse,
    Row,
    RowReverse
}

export enum FlexJustification {
    Center,
    FlexEnd,
    FlexStart,
    SpaceAround,
    SpaceEvenly,
    SpaceBetween
}

export enum FlexWrap {
    NoWrap,
    Wrap,
    WrapReverse
}

interface FlexProps {
    direction: FlexDirection;
    justification: FlexJustification;
    children?: ReactNode;
    gap?: number;
    style?: CSSProperties;
    wrap?: FlexWrap;
}

const Flex = ({ children, direction, gap, justification, style, wrap }: FlexProps) => {
    const getDirectionClass = () => {
        switch (direction) {
            case FlexDirection.Column:
                return 'direction-column';
            case FlexDirection.ColumnReverse:
                return 'direction-column-reverse';
            case FlexDirection.Row:
                return 'direction-row';
            case FlexDirection.RowReverse:
                return 'direction-row-reverse';
        }
    };

    const getJustificationClass = () => {
        switch (justification) {
            case FlexJustification.Center:
                return 'justification-center';
            case FlexJustification.FlexEnd:
                return 'justification-flex-end';
            case FlexJustification.FlexStart:
                return 'justification-flex-start';
            case FlexJustification.SpaceAround:
                return 'justification-space-around';
            case FlexJustification.SpaceEvenly:
                return 'justification-space-evenly';
            case FlexJustification.SpaceBetween:
                return 'justification-space-between';
        }
    };

    const getWrapClass = () => {
        switch (wrap) {
            case FlexWrap.NoWrap:
                return 'wrap-no-wrap';
            case FlexWrap.Wrap:
                return 'wrap-wrap';
            case FlexWrap.WrapReverse:
                return 'wrap-wrap-reverse';
            default:
                return 'wrap-no-wrap';
        }
    };

    return (
        <div className={`flex ${getDirectionClass()} ${getJustificationClass()} ${getWrapClass()}`} style={{ ...style, gap: gap ?? 0 }}>
            {children}
        </div>
    );
};

export default Flex;
