import { configureStore } from '@reduxjs/toolkit';
import { bannerReducer, snackbarReducer } from '.';
import { translationReducer } from './TranslationSlice';

export const store = configureStore({
  reducer: {
    banner: bannerReducer,
    snackbar: snackbarReducer,
    translation: translationReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;