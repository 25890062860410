import React, { KeyboardEvent, useState, useRef } from 'react';
import Icon, { IconType } from './Icon';

interface SelectProps<T> {
    label: string;
    value: T;
    values: T[];
    mapToString: (value: T) => string;
    onSelect: (value: T) => void;
    dark?: boolean;
    disabled?: boolean;
    required?: boolean;
    filled?: (value: T) => boolean;
}

const Select = <T extends any>({ dark, disabled, filled, label, required, value, values, mapToString, onSelect }: SelectProps<T>) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const gotFocusRef = useRef(false);
    const searchTextRef = useRef('');
    const valuesRef = useRef<HTMLDivElement>(null);
    const focusTimeoutRef = useRef(0);
    const keyboardTimeoutRef = useRef(0);

    const openSelect = () => {
        scrollTo(0);
        setSelectedIndex(-1);
        setOpen(true);
    };

    const closeSelect = () => {
        setOpen(false);
    };

    const handleClick = () => {
        if (!disabled) {
            if (!gotFocusRef.current) {
                open ? closeSelect() : openSelect();
            } else {
                clearTimeout(focusTimeoutRef.current);
                gotFocusRef.current = false;
            }
        }
    };

    const handleFocus = () => {
        clearTimeout(focusTimeoutRef.current);
        gotFocusRef.current = true;
        focusTimeoutRef.current = window.setTimeout(() => gotFocusRef.current = false, 1000);
        openSelect();
    };

    const handleBlur = () => {
        closeSelect();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key == 'ArrowUp') {
            event.preventDefault();

            if (open) {
                const index = selectedIndex <= -1 ? values.length - 1 : selectedIndex - 1;

                scrollToIndexTop(Math.max(index - 1, 0));
                setSelectedIndex(index);
            }
        } else if (event.key == 'ArrowDown') {
            event.preventDefault();

            if (open) {
                let index = selectedIndex >= values.length - 1 ? -1 : selectedIndex + 1;

                scrollToIndexBottom(Math.min(index + 1, values.length - 1));
                setSelectedIndex(index);
            }
        } else if (event.key == 'Enter') {
            if (open) {
                closeSelect();

                if (0 <= selectedIndex && selectedIndex < values.length) {
                    onSelect(values[selectedIndex]);
                }
            } else {
                openSelect();
            }
        } else if (event.key == ' ') {
            event.preventDefault();

            if (!open) {
                openSelect();
            }
        } else if (event.key == 'Escape') {
            if (open) {
                closeSelect();
            }
        }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        clearTimeout(keyboardTimeoutRef.current);
        searchTextRef.current += event.key;

        const index = values.findIndex(x => mapToString(x).toLowerCase().startsWith(searchTextRef.current.toLowerCase()));

        if (index >= 0) {
            scrollToIndex(Math.max(0, index - 1));
            setSelectedIndex(index);
            keyboardTimeoutRef.current = window.setTimeout(() => searchTextRef.current = '', 1000);
        } else {
            searchTextRef.current = '';
        }
    };

    const handleValueClick = (selectedValue: T, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        onSelect(selectedValue);
        closeSelect();
    };

    const handleValueMouseEnter = (index: number) => {
        setSelectedIndex(index);
    };

    const scrollTo = (top: number) => {
        if (valuesRef.current) {
            valuesRef.current.scrollTop = top;
        }
    };

    const scrollToIndex = (index: number) => {
        if (valuesRef.current) {
            valuesRef.current.scrollTop = getSelectValue(index).offsetTop;
        }
    };

    const scrollToIndexTop = (index: number) => {
        if (valuesRef.current) {
            const selectValue = getSelectValue(index);

            if (index === values.length - 2 || (selectValue.offsetTop <= valuesRef.current.scrollTop)) {
                valuesRef.current.scrollTop = selectValue.offsetTop;
            }
        }
    };

    const scrollToIndexBottom = (index: number) => {
        if (valuesRef.current) {
            const selectValue = getSelectValue(index);

            if (index === 0 || (selectValue.offsetTop + selectValue.offsetHeight >= valuesRef.current.scrollTop + valuesRef.current.offsetHeight)) {
                valuesRef.current.scrollTop = selectValue.offsetTop + selectValue.offsetHeight - valuesRef.current.offsetHeight;
            }
        }
    };

    const getSelectValue = (index: number) => valuesRef.current?.getElementsByClassName('select-value')[index] as HTMLDivElement;

    const isFilled = () => filled ? filled(value) : Boolean(value);

    return (
        <div className={`select ${open ? 'open' : ''} ${disabled ? 'disabled' : ''} ${dark ? 'dark' : ''}`} tabIndex={disabled ? undefined : 0} onClick={handleClick}
            onFocus={handleFocus} onBlur={handleBlur} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown}>
            <div className={`select-opener ${isFilled() ? 'filled' : ''}`}>
                <div className={`select-label ${required ? 'required' : ''}`}>
                    {label}
                </div>
                <div className="select-value">
                    {mapToString(value) || <br />}
                </div>
                <div className="select-arrow">
                    <Icon type={IconType.Dropdown} />
                </div>
            </div>
            <div ref={valuesRef} className={`select-values select-values-${Math.min(values.length, 5)}`}>
                {values.map((x, i) =>
                    <div key={mapToString(x)} className={`select-value ${selectedIndex === i ? 'selected' : ''}`} onClick={e => handleValueClick(x, e)} onMouseEnter={() => handleValueMouseEnter(i)}>
                        {mapToString(x)}
                    </div>)
                }
            </div>
        </div>
    );
};

export default Select;
