import React, { useRef, useState } from 'react';
import { trackEvent } from '../../services';
import Button, { ButtonType } from '../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Icon, { IconType } from '../common/Icon';
import MlfbInput from '../common/MlfbInput';
import SpacingConainer from '../common/SpacingContainer';
import Tooltip from '../common/Tooltip';
import CustomCard from './CustomCard';
import { useTranslate } from '../../hooks/common';
import { TRANSLATIONS } from '../../constants/Translations';

const SearchDocumentation = () => {
    const [inputs, setInputs] = useState({ mlfb: '', options: '', isValid: false });
    const [isMlfbInputValidating, setIsMlfbInputValidating] = useState(false);
    const cartFormRef = useRef<HTMLFormElement>(null);
    const translate = useTranslate();

    const handleMlfbChange = (mlfb: string, options: string, isValid: boolean) => setInputs({ mlfb, options, isValid });

    const handleButtonClick = () => {
        const url = `${process.env.REACT_APP_SEARCH_DOCUMENTATION_URL}${inputs.mlfb}+${encodeURIComponent(inputs.options)}`;

        window.open(url, '_blank');
        trackEvent('SearchDocumentation');
    };

    const renderCartForm = () => (
        <form ref={cartFormRef} target='_blank' method='POST' encType='multipart/form-data' action={process.env.REACT_APP_ADD_TO_CART_URL}>
            <textarea readOnly hidden id='xmlpayload' name='xmlpayload' value={getMallCartContent(inputs.mlfb, inputs.options)} />
        </form>
    );

    const getMallCartContent = (mlfb: string, options: string) =>
        `<?xml version="1.0" encoding="iso-8859-1"?>
        <!DOCTYPE cart SYSTEM "cart_v2.dtd">
        <cart IF_VERSION="2.0" APPLICATION_NAME="Marketing page">
            <item LINE_ITEMNUMBER="1" PRODUCT_ID="${mlfb}" QUANTITY="1">
                <edi_text EDITYPE="B">${options}</edi_text>
            </item>
        </cart>`;

    const disableButtons = !inputs.isValid || isMlfbInputValidating;

    return (
        <CustomCard title={translate(TRANSLATIONS.main.searchDocumentation.title)} description={translate(TRANSLATIONS.main.searchDocumentation.description)} icon={IconType.Document}>
            <SpacingConainer>
                <MlfbInput onValueChange={handleMlfbChange} onLoadStart={() => setIsMlfbInputValidating(true)} onLoadEnd={() => setIsMlfbInputValidating(false)} />
                <Flex direction={FlexDirection.Column} justification={FlexJustification.FlexEnd} gap={15} style={{ alignItems: "flex-end" }}>
                    <Tooltip id="searchDocumentationButton" text={translate(TRANSLATIONS.main.searchDocumentation.buttonTooltip)} hidden={!inputs.isValid} place="bottom">
                        <Button type={ButtonType.Secondary} disabled={disableButtons} onClick={handleButtonClick}>
                            <Icon type={isMlfbInputValidating ? IconType.Loader : IconType.Linkextern} />
                            {translate(TRANSLATIONS.main.searchDocumentation.button)}
                        </Button>
                    </Tooltip>
                    <Button type={ButtonType.Secondary} disabled={disableButtons} onClick={() => cartFormRef.current?.submit()}>
                        <Icon type={IconType.Shoppingcart} />
                        {translate(TRANSLATIONS.main.searchDocumentation.addToCart)}
                    </Button>
                </Flex>
            </SpacingConainer>
            {renderCartForm()}
        </CustomCard>
    );
}

export default SearchDocumentation;
