import React, { KeyboardEvent } from 'react';
import { useOpenAnimations } from '../../hooks/common';

export interface HeaderMegaMenuNavigationItem {
    title: string;
    to: string;
}

interface HeaderMegaMenuProps {
    open: boolean;
    navigationItems: HeaderMegaMenuNavigationItem[];
    onClose: () => void;
}

const HeaderMegaMenu = ({ open, navigationItems, onClose }: HeaderMegaMenuProps) => {
    const [animationClass, handleAnimationEnd] = useOpenAnimations(open);

    const handleNavigationItemSelect = (navigationItem: HeaderMegaMenuNavigationItem) => {
        window.open(navigationItem.to, "_blank");
        onClose();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, navigationItem: HeaderMegaMenuNavigationItem) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleNavigationItemSelect(navigationItem);
        }
    };

    return (
        <>
            <div className={`header-mega-menu ${animationClass}`}>
                <div className="header-mega-menu-background"/>
                <div className="header-mega-menu-navigation" onAnimationEnd={handleAnimationEnd}>
                    {navigationItems.map(x =>
                        <div key={x.title} className="header-mega-menu-navigation-item" tabIndex={0} onClick={() => handleNavigationItemSelect(x)} onKeyDown={e => handleKeyDown(e, x)}>
                            {x.title}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default HeaderMegaMenu;
