import React, { ReactNode } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface TooltipProps {
    children: ReactNode;
    id: string;
    text: string;
    delayShow?: number;
    hidden?: boolean;
    place?: 'top' | 'right' | 'bottom' | 'left'
}

const Tooltip = ({ children, delayShow, hidden, id, place, text }: TooltipProps) => (
    <div className='tooltip' data-for={id} data-tip={text}>
        {children}
        {!hidden &&
            
            <ReactTooltip id={id} place={place} delayShow={delayShow ?? 0} delayHide={200} style={{ backgroundColor: '#000028' }} />
        }
    </div>
);

export default Tooltip;