import { IApplicationInsights } from '@microsoft/applicationinsights-web';
import { Api, apiCall } from '.';

export const getEnvironment = () => {
    return apiCall(
        Api.isProduction(),
        async x => {
            (window as any)['isProduction'] = x.data;
        },
        async () => { }
    );
};

export const trackEvent = (eventName: string) => {
    try {
        const applicationInsights: IApplicationInsights = (window as any)['isProduction'] ? (window as any)['appInsights'] : null;

        if (applicationInsights) {
            applicationInsights.trackEvent({ name: eventName });
        }
    }
    catch (e) { }
};
