import { Translations } from '../models';

const getTranslations = <T extends Translations>(translations: T): T => translations;

export const TRANSLATIONS = getTranslations({
    'footer': {
        'contactUs': [
            'Contact us',
            'Kontakt',
            'Contact',
            'Contatti',
            'Ota yhteyttä'
        ],
        'cookieNotice': [
            'Cookie Notice',
            'Cookie-Hinweise',
            'Avis concernant les cookies',
            'Avviso sui Cookie',
            'Cookie Notice'
        ],
        'corporateInformation': [
            'Corporate Information',
            'Impressum',
            'Informations sur l\'entreprise',
            'Informazioni aziendali',
            'Corporate Information'
        ],
        'digitalId': [
            'Digital ID',
            'Digitales Zertifikat',
            'Identification numérique',
            'ID digitale',
            'Digital ID'
        ],
        'siemensWebsite': [
            'siemens.com Global Website',
            'siemens.com Germany',
            'siemens.com France',
            'siemens.com Italia',
            'siemens.com Suomi'
        ],
        'privacyNotice': [
            'Privacy Notice',
            'Datenschutz',
            'Avis de confidentialité',
            'Informativa sulla privacy',
            'Privacy Notice'
        ],
        'siemens': [
            '© Siemens 1996 - {date}',
            '© Siemens 1996 - {date}',
            '© Siemens 1996 - {date}',
            '© Siemens 1996 - {date}',
            '© Siemens 1996 - {date}'
        ],
        'termsOfUse': [
            'Terms of use',
            'Nutzungsbedingungen',
            'Conditions d\'utilisation',
            'Condizioni d\'uso',
            'Terms of use'
        ],
    },
    'header': {
        'company': [
            'Company',
            'Unternehmen',
            'Société',
            'Azienda',
            'Yhtiö'
        ],
        'language': [
            'Language',
            'Sprache',
            'Langue',
            'Lingua',
            'Kieli'
        ],
        'languages': {
            'en': [
                'English',
                'English',
                'English',
                'English',
                'English'
            ],
            'de': [
                'Deutsch',
                'Deutsch',
                'Deutsch',
                'Deutsch',
                'Deutsch'
            ],
            'fr': [
                'Français',
                'Français',
                'Français',
                'Français',
                'Français'
            ],
            'it': [
                'Italiano',
                'Italiano',
                'Italiano',
                'Italiano',
                'Italiano'
            ],
            'fi': [
                'suomi',
                'suomi',
                'suomi',
                'suomi',
                'suomi'
            ]
        },
        'marketSpecificSolutions': [
            'Market-specific Solutions',
            'Branchenlösungen',
            'Solutions spécifiques au marché',
            'Soluzione specifiche',
            'Ratkaisut'
        ],
        'productsAndServices': [
            'Products and services',
            'Produkte & Services',
            'Produits et Services',
            'Prodotti & Assistenza',
            'Tuotteet & palvelut'
        ],
        'reportProblem': [
            'Report a problem',
            'ein Problem melden',
            'Signaler un problème',
            'Segnala un problema',
            'Ilmoita ongelma'
        ],
        'skipToMainContent': [
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content',
            'Skip to main content'
        ],
        'support': [
            'Support',
            'Unterstützung',
            'Support',
            'Supporto',
            'Support'
        ]
    },
    'main': {
        'configureMotor': {
            'description': [
                'Configuration of full range of LV motors and options out of European production',
                'Konfiguration des kompletten Niederspannungsmotoren Portfolios und zugehöriger Optionen aus europäischer Fertigung',
                'Configuration d\'une gamme complète de moteurs BT et d\'options issues de la production européenne',
                'Configurazione completa di motori LV e opzioni prodotti fuori Europa',
                'Moottorin valinta koko moottorivalikoimasta'
            ],
            'title': [
                'Configure a motor',
                'Konfigurieren Sie einen Motor',
                'Configurer un moteur',
                'Configurare un motore',
                'Konfiguroi moottori'
            ]
        },
        'digitalMotor': {
            'description': [
                'Information and technical documentation on Siemens’ digitalization solutions for low voltage motors via SIDRIVE IQ Fleet and SIMOTICS CONNECT.',
                'Informationen und technische Dokumentation zu Digitalisierungslösungen für Niederspannungsmotoren von Siemens mittels SIDRIVE IQ Fleet und SIMOTICS CONNECT.',
                'Information et documentation technique sur les solutions de digitalisation numérique SIEMENS pour les moteurs basse tension via SIDRIVE IQ Fleet et SIMOTIC Connect.',
                'Informationi e documentazione tecnica in merito alle soluzioni di digitalizzazione Siemens per motori di bassa tensione tramite SIDRIVE IQFleet e SIMOTICS Connect',
                'Lisätietoa ja tekninen dokumentointi Siemensin SIDRIVE IQ Fleet ja SIMOTICS CONNECT digitalisaatioratkaisuista pienjännitemoottoreille'
            ],
            'title': [
                'Condition Monitoring for Low Voltage Motor',
                'Condition Monitoring für Niederspannungsmotoren',
                'Système de surveillance pour moteurs asynchrones basse tension',
                'Monitoraggio delle condizioni operative per motori a bassa tensione',
                'Kunnonvalvontaratkaisu pienjännitemoottoreille'
            ]
        },
        'findContact': {
            'buttonTooltip': [
                'Looking for technical support with regard to low voltage motors?',
                'Suchen Sie technische Unterstützung für Niederspannungsmotoren?',
                'Vous cherchez une assistance technique pour les moteurs basse tension ?',
                'Cerchi supporto tecnico per i motori a bassa tensione?',
                'Etsitkö teknistä tukea pienjännitemoottoreille?'
            ],
            'lowVoltageMotors': [
                'Low voltage motors',
                'Niederspannungsmotoren',
                'Moteurs basse tension',
                'Motori bassa tensione',
                'Pienjännitemoottorit'
            ],
            'technicalSupport': [
                'Sales',
                'Vertrieb',
                'Ventes',
                'Vendita',
                'Myynti'
            ],
            'title': [
                'Find the right contact',
                'Finden Sie den richtigen Ansprechpartner',
                'Trouver le bon contact',
                'Trova il contatto corretto',
                'Löydä oikea yhteyshenkilö'
            ]
        },
        'industryMall': {
            'description': [
                'Product catalogue and online ordering system for the Industry Automation and Drive Technology.',
                'Katalog- und Bestellsystem für Automatisierungs- und Antriebstechnik',
                'Catalogue de produits et système de commande en ligne pour l\'automatisation industrielle et les techniques d\'entraînement.',
                'Catalogo prodotti e ordini on-line per Industry Automation e Drive Technology',
                'Katalogi - ja tilausjärjestelmä teollisuusautomaatiolle ja sähkökäytöille'
            ],
            'title': [
                'Go to Industry Mall',
                'Link zur Industry Mall',
                'Aller à Industry Mall',
                'Vai a Industry Mall',
                'Industry Malliin'
            ]
        },
        'meps': {
            'description': [
                'Get information about current valid and upcoming ErP regulations worldwide',
                'Informieren Sie sich über aktuelle gültige und kommende ErP-Bestimmungen weltweit',
                'Obtenez des informations sur les règlementations de performance énergétiques en vigueur et à venir dans le monde entier',
                'Ottieni informazioni in merito alle normative ErP attuali e future in tutto il mondo.',
                'Lisätietoa voimassa olevista ja tulevista ErP vaatimuksista maailmanlaajuisesti'
            ],
            'title': [
                'Minimum Energy Performance Standards – MEPS',
                'Minimum Energy Performance Standards – MEPS',
                'Normes de performance énergétique minimum dans le monde : MEPS',
                'Minimum Energy Performance Standards – MEPS',
                'Minimihyötysuhdestandardit – MEPS (Minimum Energy Performance Standards)'
            ]
        },
        'passionForMotors': {
            'description': [
                'Good reasons to choose SIMOTICS motors. We’ve invested heavily in recent years to upgrade our manufacturing capacity, extend our digitalization services and improve our customer care. Key benefits for you.',
                'Viele Gründe sprechen für SIMOTICS Motoren. Wir haben in den letzten Jahren massiv in die Modernisierung unserer Produktionskapazitäten, den Ausbau unserer Digitalisierungsservices und in das Vertrauen unserer Kunden investiert. Wie Sie als Kunde davon profitieren.',
                'De bonnes raisons de choisir les moteurs SIMOTICS. Nous avons beaucoup investi ces dernières années pour améliorer notre capacité de fabrication, étendre nos services de numérisation et améliorer notre service clientèle. Principaux avantages pour vous.',
                'Buoni motivi per scegliere i motori SIMOTICS. Negli ultimi anni abbiamo investito molto per migliorare la nostra capacità produttiva, estendere i nostri servizi di digitalizzazione e migliorare l\'assistenza ai nostri clienti.Principali vantaggi per te.',
                'Hyvä syy valita SIMOTICS moottori. Olemme investoineet viime vuosina paljon päivittääksemme tuotantokapasiteettiamme, laajentaaksemme digitalisaatiopalveluitamme ja parantaaksemme asiakaspalveluamme. Tutustu siihen mitä #passionformotors tarkoittaa käytännössä'
            ],
            'title': [
                'Experience Passion for motors',
                'Experience Passion for motors',
                'Découvrez \"Passion for motors\"',
                'Sperimenta Passion for Motors',
                'Intohimona moottorit'
            ]
        },
        'searchCertificate': {
            'description': [
                'Access the most common certificates.',
                'Zugriff auf die gängigsten Zertifikate.',
                'Accédez aux certificats les plus courants.',
                'Accesso ai certificati più comuni',
                'Hae yleisimmät sertifkaatit.'
            ],
            'donwloadCertificate': [
                'Download certificate',
                'Zertifikat herunterladen',
                'Télécharger le certificat',
                'Scarica certificato',
                'Lataa sertifikaatti'
            ],
            'selectCategory': [
                'Select a category',
                'Wählen Sie eine Kategorie',
                'Sélectionnez une catégorie',
                'Seleziona una categoria',
                'Valitse kategoria'
            ],
            'selectCertifier': [
                'Select a certifier',
                'Wählen Sie einen Zertifizierer aus',
                'Choisir un certificateur',
                'Seleziona un certificato',
                'Valitse sertifioija'
            ],
            'selectLocation': [
                'Select a location',
                'Wähle einen Ort',
                'Sélectionnez un lieu',
                'Seleziona un luogo',
                'Valitse sijainti'
            ],
            'title': [
                'Search for a certificate',
                'Schnellzugriff auf Zertifikate',
                'Recherche d\'un certificat',
                'Ricerca certificati',
                'Etsi sertifikaattia'
            ]
        },
        'searchDocumentation': {
            'button': [
                'Go to DT Configurator for more documentation.',
                'Weitere Unterlagen finden Sie im DT-Konfigurator.',
                'Allez au DT Configurator pour plus de documentation.',
                'Vai al Configuratore DT per ulteriori documenti',
                'Dokumentaatioon'
            ],
            'buttonTooltip': [
                'Configuration of full range of LV motors and options out of European production',
                'Konfiguration des kompletten Niederspannungsmotoren Portfolios und zugehöriger Optionen aus europäischer Fertigung',
                'Configuration d\'une gamme complète de moteurs BT et d\'options issues de la production européenne',
                'Configurazione completa di motori LV e opzioni prodotti fuori Europa',
                'Siirry lataamaan moottorin dokumentaatiota'
            ],
            'description': [
                'Direct access to data sheet and technical drawing for your product.',
                'Direkter Zugriff auf das Datenblatt und die technische Zeichnung für Ihr Produkt.',
                'Accès direct à la fiche technique et au plan d\'encombrements de votre produit.',
                'Data sheet e disegno dimensionale per il prodotto indicato',
                'Suora pääsy moottorisi datalehteen ja mittakuvaan.'
            ],
            'title': [
                'Search for documentation',
                'Schnellzugriff auf Dokumentationsunterlagen',
                'Recherche de documentation',
                'Cerca documentazione',
                'Etsi dokumentaatiota'
            ],
            'addToCart': [
                'Add to cart',
                'In den Warenkorb',
                'Ajouter au panier',
                'Aggiungi al carrello',
                'Lisää ostoskoriin'
            ]
        },
        'searchSpareParts': {
            'buttonTooltip': [
                'Both, MLFB and serial number, need to be entered for a valid result.',
                'MLFB sowie Seriennummer müssen eingegeben werden, um ein gültiges Ergebnis zu erhalten.',
                'Les deux, le MLFB et le numéro de série, doivent être entrés pour un résultat valide.',
                'Sia il codice MLFB che il numero di serie devono essere inseriti per ottenere un risultato valido.',
                'Tilauskoodi ja sarjanumero on syötettävä molemmat oikean tuloksen saamiseksi'
            ],
            'description': [
                'Both, MLFB and serial number, need to be entered for a valid result.',
                'MLFB sowie Seriennummer müssen eingegeben werden, um ein gültiges Ergebnis zu erhalten.',
                'Les deux, le MLFB et le numéro de série, doivent être entrés pour un résultat valide.',
                'Sia il codice MLFB che il numero di serie devono essere inseriti per ottenere un risultato valido.',
                'Tilauskoodi ja sarjanumero on syötettävä molemmat oikean tuloksen saamiseksi'
            ],
            'informationTooltip': [
                'Product information and input assistance for motor spare parts information',
                'Produktinformation und Eingabehilfe für Motoren Ersatzteilauskunft',
                'Informations sur les produits et aide à la saisie des informations sur les pièces de rechange pour moteurs',
                'Informazioni sul prodotto ed assistenza per informazioni sulle parti di ricambio del motore',
                'Tuotetietoa ja apua moottorin varaosien löytämiseen'
            ],
            'search': [
                'Search',
                'Suchen',
                'Recherchez',
                'Cerca',
                'Etsi'
            ],
            'serialNumber': [
                'Serial Number',
                'Seriennummer',
                'Numéro de série',
                'Numerio di serie',
                'Sarjanumero'
            ],
            'title': [
                'Search for Spare parts',
                'Ersatzteile suchen',
                'Recherche de pièces de rechange',
                'Ricerca parti di ricambio',
                'Etsi varaosia'
            ]
        },
        'selectMotor': {
            'description': [
                'Fast and easy selection of LV motors (standard portfolio)',
                'Schnelle und einfache Auswahl von Niederspannungsmotoren (Standard Portfolio)',
                'Sélection rapide et facile des moteurs BT (portfolio standard)',
                'Seleziona rapida motore LV ( portfolio standard )',
                'Nopea ja helppo moottorin valinta'
            ],
            'title': [
                'Select a motor',
                'Wählen Sie einen Motor aus',
                'Sélectionner un moteur',
                'Seleziona un motore',
                'Valitse moottori'
            ]
        },
        'simolog': {
            'button': [
                'Siemens Partner Extranet',
                'Siemens Partner Extranet',
                'Extranet Partenaires Siemens',
                'Siemens Partner Extranet',
                'Siemens Partner Extranet'
            ],
            'description1': [
                'The SIMOLOG (Siemens Modification and Logistics) Program addresses all our Partners that modify within a short time motors on stock according to customer specification.',
                'Das SIMOLOG (Siemens Modifikation and Logistik) Programm richtet sich an unsere Partner, die innerhalb kürzester Zeit Motoren entsprechend der Kundenanforderungen modifizieren.',
                'Le programme SIMOLOG (Siemens Modification and Logistics) s\'adresse à tous nos partenaires qui modifient dans un délai court des moteurs en stock selon les besoins du client.',
                'Il programma SIMOLOG (Siemens Modification and Logistics) si rivolge a tutti i nostri partner che modificano in breve tempo motori a magazzino in base alle esigenze del cliente.',
                'SIMOLOG-ohjelmaan (Siemens Modification and Logistics) kuuluvat kaikki partnerimme, jotka varastoivat moottoreitamme ja pystyvät muokkaamaan niitä asiakastarpeiden mukaisesti.'
            ],
            'description2': [
                'Access exclusive information for our SIMOLOG Partners via the Siemens Partner Extranet.',
                'Exklusive Informationen für unsere SIMOLOG-Partner erhalten Sie über das Siemens-Partner-Extranet.',
                'Accédez à des informations exclusives pour nos partenaires SIMOLOG via l\'Extranet dédié.',
                'Accesso ad informazioni esclusive per i nostri SIMOLOG partner tramite la Partner Extranet di Siemens.',
                'Lisätietoa SIMOLOG-partnereille löytyy \"Siemens Partner Extranet\":istä.'
            ],
            'title': [
                'SIMOLOG Partner Program',
                'SIMOLOG-Partnerprogramm',
                'Programme partenaires SIMOLOG',
                'Programma SINMOLOG Partner',
                'SIMOLOG-partneriohjelma'
            ]
        },
        'simoticsLvMotors': [
            'SIMOTICS LV Motors',
            'SIMOTICS Niederspannungsmotoren',
            'Moteurs SIMOTICS BT',
            'Motori SIMOTICS LV',
            'SIMOTICS moottorit'
        ],
        'IE4': {
            'description': [
                'Information about the upcoming regulation, our IE4 portfolio, video guides & customer references.',
                'Information about the upcoming regulation, our IE4 portfolio, video guides & customer references.',
                'Information about the upcoming regulation, our IE4 portfolio, video guides & customer references.',
                'Information about the upcoming regulation, our IE4 portfolio, video guides & customer references.',
                'Information about the upcoming regulation, our IE4 portfolio, video guides & customer references.'
            ],
            'title': [
                'Are you Ready For IE4?',
                'Are you Ready For IE4?',
                'Are you Ready For IE4?',
                'Are you Ready For IE4?',
                'Are you Ready For IE4?'
            ]
        },
        'sinasave': {
            'description': [
                'Determination of the energy saving potential and payback times based on your particular application conditions.',
                'Ermittlung von Energieeinsparpotentiale und Amortisationszeiten auf Basis Ihrer individuellen Einsatzbedingungen.',
                'Détermination du potentiel d\'économie d\'énergie et des délais d\'amortissement en fonction de vos conditions particulières d\'application.',
                'Determinazione del potenziale risparmio energetico e dei tempi di ammortamento in base alle particolari condizioni dell\' applicazione.',
                'Energiansäästöpotentiaalin ja takaisinmaksuajan määrittely käyttökohteen perusteella.'
            ],
            'title': [
                'Determine your energy saving potential and your payback time',
                'Ermitteln Sie Ihr Einsparpotenzial und Amortisationszeiten',
                'Déterminez votre potentiel d\'économie d\'énergie et votre temps de retour sur investissement',
                'Determina il tuo potenziale di risparmio energetico ed i tuoi tempi di ammortamento',
                'Määritä energiansäästöpotentiaali ja takaisinmaksuaika'
            ]
        },
        'sizer': {
            'description': [
                'Based on your application and the mechanical system dimension your frequency converters, motors and gearboxes.',
                'Ausgehend von Ihrer Anwendung und des mechanischen Systems dimensionieren Sie Ihre Frequenzumrichter, Motoren und Getriebe.',
                'En fonction de votre application et du système mécanique, dimensionnez vos convertisseurs de fréquence, moteurs et réducteurs.',
                'In base all\'applicazione ed alla dimensione del sistema meccanico, convertitori di frequenza, motori e riduttori.',
                'Valitse moottori, taajuusmuuttaja ja vaihde käyttökohteen ja mekaniikan pohjalta.'
            ],
            'title': [
                'Drive System Dimensioning',
                'Antriebsauslegung',
                'Dimensionnement du système avec Variateur',
                'Dimensionamento del sistema di azionamento',
                'Sähkökäytön mitoitus'
            ]
        },
        'toolsAndServices': [
            'Tools & Service',
            'Tools & Service',
            'Outils et Services',
            'Tools & Service',
            'Työkalut & palvelut'
        ],
        'mlfbInput': {
            'notCompleteWarning': [
                'Order number not complete (e.g. 1LE10010EA422AA0-Z B02+G40)',
                'Bestellnummer unvollständig (z.B. 1LE10010EA422AA0-Z B02+G40)',
                'Le numéro de commande n\'est pas complet(par exemple 1LE10010EA422AA0 - Z B02 + G40)',
                'Codice non completo ( es. 1LE10010EA422AA0-Z B02+G40)',
                'Tilausnumero puutteellinen (esim. 1LE10010EA422AA0-Z B02+G40)'
            ],
            'notValidMlfbWarning': [
                'Mlfb or option not exists ({errorMessage})',
                'Mlfb or option not exists ({errorMessage})',
                'Mlfb or option not exists ({errorMessage})',
                'Mlfb or option not exists ({errorMessage})',
                'Mlfb or option not exists ({errorMessage})'
            ],
            'orderNumberEndsHyphenError': [
                'Syntax error in order number at {index}. Cannot end with \' - \'',
                'Syntaxfehler in der Bestellnummer an Stelle {index}. \' - \' ist ein ungültiger Abschluss',
                'Syntax error in order number at {index}. Cannot end with \' - \'',
                'Syntax error in order number at {index}. Cannot end with \' - \'',
                'Syntaktivirhe indexissä {index}. Tilausnumero ei voi loppua \' - \''
            ],
            'orderNumberSyntaxError': [
                'Syntax error in order number at {index}. character: \'{character}\'',
                'Syntaxfehler in der Bestellnummer an Stelle {index}. Zeichen: \'{character}\'',
                'Syntax error in order number at {index}. character: \'{character}\'',
                'Syntax error in order number at {index}. character: \'{character}\'',
                'Syntaksivirhe indexissä {index}. Merkki: \'{character}\''
            ],
            'optionCodesSpaceRequiredError': [
                'Syntax error in option codes: space required after order number',
                'Syntaxfehler in den Optionen: Leerzeichen nach der Bestellnummer erforderlich',
                'Syntax error in option codes: space required after order number',
                'Syntax error in option codes: space required after order number',
                'Syntaksivirhe optioissa: Lisää välilyönti ennen optiokoodeja'
            ],
            'optionCodesSyntaxError': [
                'Syntax error in option codes',
                'Syntaxfehler in den Optionen',
                'Syntax error in option codes',
                'Syntax error in option codes',
                'Syntaksivirhe optioissa'
            ]
        }
    }
});
