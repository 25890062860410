import React, { ReactNode } from 'react';

interface KeyVisualProps {
    height: number;
    url: string;
    children?: ReactNode;
}

const KeyVisual = ({ children, height, url }: KeyVisualProps) => (
    <div className="key-visual" style={{ height: `${height}px`, backgroundImage: `url(${url})` }}>
        {children}
    </div>
);

export default KeyVisual;
