import { TRANSLATIONS } from '../../constants/Translations';
import { useTranslate } from '../../hooks/common';
import { useLocalizedLink } from '../../hooks/common/LocalizedLink';
import { trackEvent } from '../../services';
import Button, { ButtonType } from '../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Icon, { IconType } from '../common/Icon';
import Select from '../common/Select';
import SpacingConainer from '../common/SpacingContainer';
import Tooltip from '../common/Tooltip';
import CustomCard from './CustomCard';

const FindContact = () => {
    const translate = useTranslate();
    const technicalSupport = translate(TRANSLATIONS.main.findContact.technicalSupport);
    const lowVoltageMotors = translate(TRANSLATIONS.main.findContact.lowVoltageMotors);
    const localizedLink = useLocalizedLink();
    const url = localizedLink(process.env.REACT_APP_FIND_CONTACT_URL)

    const handleButtonClick = () => {
        window.open(url, '_blank');
        trackEvent('FindContact');
    };

    return (
        <CustomCard title={translate(TRANSLATIONS.main.findContact.title)} wide>
            <SpacingConainer>
                <Select label={technicalSupport} value={technicalSupport} values={[technicalSupport]} mapToString={x => x} onSelect={x => { }} />
                <Select label={lowVoltageMotors} value={lowVoltageMotors} values={[lowVoltageMotors]} mapToString={x => x} onSelect={x => { }} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Tooltip id='findContactButton' text={translate(TRANSLATIONS.main.findContact.buttonTooltip)} place='bottom'>
                        <Button type={ButtonType.Secondary} onClick={handleButtonClick}>
                            <Icon type={IconType.Linkextern} />
                            {translate(TRANSLATIONS.main.findContact.title)}
                        </Button>
                    </Tooltip>
                </Flex>
            </SpacingConainer>
        </CustomCard>
    );
};

export default FindContact;
