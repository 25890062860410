import { useAppSelector } from './TypedRedux'

export const useLocalizedLink = () => {
  return (key: string | undefined) => {
    if (key === undefined || key === null) {
      console.error(`key does not exists!`);
      return '';
    }

    const language = useAppSelector(state => state.translation.language);
    const urls = key.split(',');

    return urls[language];
  }
}
