import React, { KeyboardEvent, MouseEvent, useState } from 'react';
import { HeaderTabType } from '../../enums';

export interface Tab {
    title: string;
    to: string;
    type: HeaderTabType;
}

interface HeaderTabsProps {
    hidden: boolean;
    tabs: Tab[];
}

const HeaderTabs = ({ hidden, tabs }: HeaderTabsProps) => {
    const [underline, setUnderline] = useState({ left: -1, width: -1 });

    const handleSelect = (tab: Tab) => {
        window.open(tab.to, "_blank");
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, tab: Tab) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleSelect(tab);
        }
    };

    const handleMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
        setUnderline({ left: event.currentTarget?.offsetLeft ?? -1, width: event.currentTarget?.offsetWidth ?? -1 });
    };

    const handleMouseLeave = () => {
        setUnderline({ left: -1, width: -1 });
    };

    return (
        <div className="header-tabs">
            {tabs.map(x =>
                <div key={x.title} className="tab" tabIndex={hidden ? undefined : 0} onClick={() => handleSelect(x)} onKeyDown={e => handleKeyDown(e, x)}
                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div >
                        {x.title}
                    </div>
                </div>
            )}
            {underline.left >= 0 && underline.width >= 0 &&
                <div className="header-tab-underline" style={{ left: underline.left, width: underline.width }} />
            }
        </div>
    );
};

export default HeaderTabs;
