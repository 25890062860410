import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Snackbar } from '../components/common/Snackbars';
import { ReactText } from 'react';

export interface SnackbarState {
  snackbars: Snackbar[];
}

const initialState: SnackbarState = {
  snackbars: []
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    addSnackbar: (state: SnackbarState, action: PayloadAction<Snackbar>) => {
      state.snackbars = [action.payload, ...state.snackbars];
    },
    removeSnackbar: (state: SnackbarState, action: PayloadAction<ReactText>) => {
      state.snackbars = state.snackbars.filter(s => s.id !== action.payload);
    }
  }
});

export const { addSnackbar, removeSnackbar } = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;