import React, { ReactNode } from 'react';
import Icon, { IconType } from '../common/Icon';

interface CustomCardProps {
    children?: ReactNode;
    description?: string;
    title: string;
    icon?: IconType;
    wide?: boolean;
}

const CustomCard = ({ children, description, title, icon, wide }: CustomCardProps) => (
    <div className={`custom-card ${wide ? 'wide' : ''}`}>
        <div className="header">
            <div className="title-container">
                <div className="title">
                    {title}
                </div>
                {description &&
                    <div className="description">
                        {description}
                    </div>
                }
            </div>
            {icon &&
                <Icon type={icon} />
            }
        </div>
        <div className="content">
            {children}
        </div>
    </div>
);

export default CustomCard;
