import React, { ReactText } from 'react';

interface HeadlineProps {
    label?: string;
    line1: ReactText | JSX.Element;
    line2?: ReactText | JSX.Element;
}

const Headline = ({ label, line1, line2 }: HeadlineProps) => (
    <div className="headline">
        <div className={`headline-bar ${label ? 'with-label' : ''}`} />
        <span className="headline-text">
            {label &&
                <div className="headline-label">
                    {label}
                </div>
            }
            <div className="headline-line">
                {line1}
            </div>
            {line2 &&
                <div className="headline-line">
                    {line2}
                </div>
            }
        </span>
    </div>
);

export default Headline;
