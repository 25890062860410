import React, { ChangeEvent, CSSProperties } from 'react';

interface InputProps {
    label: string;
    name: string;
    value: string;
    onChange: (value: string, name: string) => void;
    dark?: boolean;
    disabled?: boolean;
    placeholder?: string;
    readonly?: boolean;
    required?: boolean;
    style?: CSSProperties;
    getErrorMessage?: (value: string) => string;
}

const TextInput = ({ dark, disabled, placeholder, readonly, required, label, name, style, value, getErrorMessage, onChange }: InputProps) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value, event.target.name);
    };

    const errorMessage = getErrorMessage ? getErrorMessage(value) : '';

    return (
        <div className={`text-input ${dark ? 'dark' : ''}`} style={style}>
            <div className="text-input-field">
                <input className={errorMessage ? 'error' : ''} type="text" name={name} placeholder={placeholder} value={value} disabled={disabled} readOnly={readonly} required={required} onChange={handleChange} />
                <div className={`text-input-label ${placeholder || Boolean(value) ? 'filled' : ''}`}>
                    {label}
                </div>
            </div>
            {getErrorMessage &&
                <div className="text-input-error">
                    {errorMessage ? errorMessage : <br />}
                </div>
            }
        </div>
    );
};

export default TextInput;
