import { ApiBase } from '.';
import { ServiceDataResult } from '../models';

export class Api extends ApiBase {
    static isProduction() {
        return this.get('api/environment/production') as Promise<ServiceDataResult<boolean>>;
    }

    static validateMlfbWithOptions(mlfbWithOptions: string) {
        return this.post('api/marketingpage/validateMlfbWithOptions', mlfbWithOptions);
    }
}
