import React, { useState } from 'react';
import { trackEvent } from '../../services';
import { Certificate } from '../../models';
import { certificates } from '../../constants';
import Button, { ButtonType } from '../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Icon, { IconType } from '../common/Icon';
import Select from '../common/Select';
import SpacingConainer from '../common/SpacingContainer';
import CustomCard from './CustomCard';
import { useAppSelector, useTranslate } from '../../hooks/common';
import { Language } from '../../enums';
import { TRANSLATIONS } from '../../constants/Translations';

const SearchCertificate = () => {
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [certifier, setCertifier] = useState('');
    const language = useAppSelector(state => state.translation.language);
    const languageCode = Language[language].toLowerCase();
    const translate = useTranslate();

    const handleCategorySelect = (category: string) => {
        const availableLocations = getAvailableCertificateValues('location', category, null, null);

        setCategory(category);
        setLocation(availableLocations.length === 1 ? availableLocations[0] : '');
        certifier.length > 0 && setCertifier('');
    };

    const handleLocationSelect = (location: string) => {
        const availableCertificates = getAvailableCertificateValues('certifier', category, location, null);

        setLocation(location);
        setCertifier(availableCertificates.length === 1 ? availableCertificates[0] : '');
    };

    const handleButtonClick = () => {
        const certificateLink = getAvailableCertificateValues('link', category, location, certifier);

        window.open(`${certificateLink}-${languageCode}.pdf`, '_blank');
        trackEvent('SearchCertificate');
    };

    const getAvailableCertificateValues = (property: keyof Certificate, category: string | null, location: string | null, certifier: string | null) => {
        return certificates.filter(x => (category === null || x.system === category) && (location === null || x.location === location)
            && (certifier === null || x.certifier === certifier)).map(x => x[property]).filter((x, i, a) => a.indexOf(x) === i);
    };

    return (
        <CustomCard title={translate(TRANSLATIONS.main.searchCertificate.title)} description={translate(TRANSLATIONS.main.searchCertificate.description)} icon={IconType.Bookmark}>
            <SpacingConainer>
                <Select label={translate(TRANSLATIONS.main.searchCertificate.selectCategory)}
                    value={category}
                    values={getAvailableCertificateValues('system', null, null, null)}
                    mapToString={x => x}
                    onSelect={handleCategorySelect}
                />
                <Select label={translate(TRANSLATIONS.main.searchCertificate.selectLocation)}
                    value={location}
                    values={getAvailableCertificateValues('location', category, null, null)}
                    disabled={!category}
                    mapToString={x => x}
                    onSelect={handleLocationSelect}
                />
                <Select label={translate(TRANSLATIONS.main.searchCertificate.selectCertifier)}
                    value={certifier}
                    values={getAvailableCertificateValues('certifier', category, location, null)}
                    disabled={!location}
                    mapToString={x => x}
                    onSelect={x => setCertifier(x)}
                />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd}>
                    <Button type={ButtonType.Secondary} onClick={handleButtonClick} disabled={!category || !location || !certifier}>
                        <Icon type={IconType.Linkextern} />
                        {translate(TRANSLATIONS.main.searchCertificate.donwloadCertificate)}
                    </Button>
                </Flex>
            </SpacingConainer>
        </CustomCard>
    );
};

export default SearchCertificate;
