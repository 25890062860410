import { Certificate } from "../models";

export const certificates: Certificate[] = [
    {system: "Quality Management - ISO 9001", certifier: "DQS", location: "Nuremberg", link: process.env.REACT_APP_QUALITY_MANAGEMENT_CERT_URL as string },
    {system: "Quality Management - ISO 9001", certifier: "DQS", location: "Frenstát", link: process.env.REACT_APP_QUALITY_MANAGEMENT_CERT_URL as string },
    {system: "Quality Management - ISO 9001", certifier: "DQS", location: "Mohelnice", link: process.env.REACT_APP_QUALITY_MANAGEMENT_CERT_URL as string },

    {system: "Environment Management - ISO 14001", certifier: "DQS", location: "Nuremberg", link: process.env.REACT_APP_ENVIRONMENT_MANAGEMENT_CERT_URL as string },
    {system: "Environment Management - ISO 14001", certifier: "DQS", location: "Frenstát", link: process.env.REACT_APP_ENVIRONMENT_MANAGEMENT_CERT_URL as string },
    {system: "Environment Management - ISO 14001", certifier: "DQS", location: "Mohelnice", link: process.env.REACT_APP_ENVIRONMENT_MANAGEMENT_CERT_URL as string },

    {system: "Production Quality Assessment Notification - 2014/34/EU", certifier: "PTB", location: "Nuremberg", link: process.env.REACT_APP_PRODUCT_QUALITY_ASSESSMENT_NOTIFICATION_URL as string },
    {system: "Production Quality Assessment Notification - 2014/34/EU", certifier: "DEKRA EXAM", location: "Frenstát", link: process.env.REACT_APP_PRODUCT_QUALITY_ASSESSMENT_NOTIFICATION_FRENSTAT_MOHELNICE_URL as string },
    {system: "Production Quality Assessment Notification - 2014/34/EU", certifier: "DEKRA EXAM", location: "Mohelnice", link: process.env.REACT_APP_PRODUCT_QUALITY_ASSESSMENT_NOTIFICATION_FRENSTAT_MOHELNICE_URL as string },

    {system: "Occupational Health and Safety Management - ISO 45001", certifier: "DQS", location: "Nuremberg", link: process.env.REACT_APP_OCCUPATIONAL_HEALTH_AND_SAFETY_MANAGEMENT_CERT_URL as string },
    {system: "Occupational Health and Safety Management - ISO 45001", certifier: "DQS", location: "Frenstát", link: process.env.REACT_APP_OCCUPATIONAL_HEALTH_AND_SAFETY_MANAGEMENT_CERT_URL as string },
    {system: "Occupational Health and Safety Management - ISO 45001", certifier: "DQS", location: "Mohelnice", link: process.env.REACT_APP_OCCUPATIONAL_HEALTH_AND_SAFETY_MANAGEMENT_CERT_URL as string }
];
