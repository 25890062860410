import { KeyboardEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import siemensLogo from '../../../assets/images/siemens-logos/siemens-logo-petrol.svg';
import { TRANSLATIONS } from '../../constants/Translations';
import { HeaderTabType, Language } from '../../enums';
import { useAppSelector, useResized, useScrolledDown, useTranslate } from '../../hooks/common';
import { useLocalizedLink } from '../../hooks/common/LocalizedLink';
import { removeBanner } from '../../store/BannerSlice';
import { setLanguage } from '../../store/TranslationSlice';
import Banners from '../common/Banners';
import ContentContainer from '../common/ContentContainer';
import { IconType } from '../common/Icon';
import MenuIcon from '../common/MenuIcon';
import HeaderAction, { HeaderActionProps, HeaderDropdownAction } from './HeaderAction';
import HeaderMegaMenu from './HeaderMegaMenu';
import HeaderTabs, { Tab } from './HeaderTabs';

interface HeaderProps {
    title?: string;
}

type LanguageType = 'en' | 'de' | 'fr' | 'it' | 'fi';

const Header = ({ title }: HeaderProps) => {
    const dispatch = useDispatch();
    const banners = useAppSelector(state => state.banner?.banners);
    const [menuOpen, setMenuOpen] = useState(false);
    const scrolledDown = useScrolledDown();
    const size = useResized();
    const languages = Object.keys(Language).slice(0, Math.ceil(Object.keys(Language).length / 2)).map(l => Number(l));
    const translate = useTranslate();
    const localizedLink = useLocalizedLink();

    const handleSkipButtonKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();

            location.hash = '';
            location.hash = '#main';
        }
    };

    const headerActions: HeaderActionProps[] = [
        {
            dropdownActions: languages.map<HeaderDropdownAction>(x => ({ name: translate(TRANSLATIONS.header.languages[Language[x].toLowerCase() as LanguageType]), onClick: () => dispatch(setLanguage(x)) })),
            iconType: IconType.Language,
            title: translate(TRANSLATIONS.header.language)
        },
        {
            dropdownActions: [{ name: translate(TRANSLATIONS.header.reportProblem), onClick: () => window.location.href = 'mailto:apps-help.industry@siemens.com?subject=Feedback for Marketingpage', iconType: IconType.Email }],
            iconType: IconType.Support,
            title: translate(TRANSLATIONS.header.support)
        }
    ];

    const tabs: Tab[] = [
        {
            title: translate(TRANSLATIONS.header.productsAndServices),
            to: localizedLink(process.env.REACT_APP_SIEMENS_URL),
            type: HeaderTabType.ProductsAndServices
        },
        {
            title: translate(TRANSLATIONS.header.marketSpecificSolutions),
            to: localizedLink(process.env.REACT_APP_MARKETING_SPECIFIC_SOLUTIONS_URL),

            type: HeaderTabType.MarketSpecificSolutions
        },
        {
            title: translate(TRANSLATIONS.header.company),
            to: localizedLink(process.env.REACT_APP_SIEMENS_URL),
            type: HeaderTabType.Company
        }
    ];

    return (
        <header>
            <div className="main-row-container">
                <ContentContainer header>
                    <div className="main-row">
                        <div className="skip-button" tabIndex={0} onKeyDown={handleSkipButtonKeyDown}>
                            {translate(TRANSLATIONS.header.skipToMainContent)}
                        </div>
                        <div className="header-row-container">
                            <img className="siemens-logo" src={siemensLogo} alt="Siemens Logo" />
                        </div>
                        {title &&
                            <div className="header-row-container">
                                <div className="title">{title}</div>
                            </div>
                        }
                        <div className="action-container">
                            {headerActions.map(x => <HeaderAction key={x.title} dropdownActions={x.dropdownActions} iconType={x.iconType} title={x.title} />)}
                            <div className="header-row-container">
                                <MenuIcon open={menuOpen} onClick={() => setMenuOpen(x => !x)} />
                            </div>
                        </div>
                    </div>
                </ContentContainer>
            </div>
            <div className={`addtitional-rows-container ${scrolledDown ? 'hiden' : ''}`}>
                <div className="secondary-row-container">
                    <ContentContainer header>
                        <div className="secondary-row">
                            <HeaderTabs hidden={scrolledDown || size.width <= 767} tabs={tabs} />
                        </div>
                    </ContentContainer>
                </div>
                <Banners banners={banners ?? []} removeBanner={x => dispatch(removeBanner(x))} />
            </div>
            <HeaderMegaMenu navigationItems={tabs.map(x => ({ ...x }))} open={menuOpen} onClose={() => setMenuOpen(false)} />
        </header>
    );
};

export default Header;
