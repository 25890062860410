import { useEffect, useState } from 'react';

export const useResized = () => {
    const [size, setSize] = useState({height: 0, width: 0});

    useEffect(() => {
        const handleResize = () => {
            setSize({ height: window.innerHeight, width: window.innerWidth });
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return size;
}
