import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import IE4 from '../../../assets/images/IE4.jpg';
import keyVisual from '../../../assets/images/background.png';
import digitalMotor from '../../../assets/images/digital-motor.jpg';
import dtkSelector from '../../../assets/images/dtk-selector.png';
import industryMall from '../../../assets/images/industryMall.jpg';
import meps from '../../../assets/images/meps.jpg';
import motorCheckboxes from '../../../assets/images/motorCheckboxes.png';
import motorSlider from '../../../assets/images/motorSlider.png';
import overviewDe from '../../../assets/images/overview-de.png';
import overviewEn from '../../../assets/images/overview-en.png';
import passionForMotors from '../../../assets/images/passionformotors.jpg';
import simolog from '../../../assets/images/simolog.jpeg';
import simpleSelectorCut from '../../../assets/images/simple-selector-cut.png';
import sinasave from '../../../assets/images/sinasave.png';
import sizer from '../../../assets/images/sizer.jpg';
import technicalDataDe from '../../../assets/images/technical-data-de.png';
import technicalDataEn from '../../../assets/images/technical-data-en.png';
import video from '../../../assets/videos/video.mp4';
import { TRANSLATIONS } from '../../constants/Translations';
import { Language } from '../../enums';
import { useAppSelector, useEnvironmentVariable, useLocalizedLink, useTranslate } from '../../hooks/common/index';
import Card, { CardType } from '../application/Card';
import FindContact from '../application/FindContact';
import SearchCertificate from '../application/SearchCertificate';
import SearchDocumentation from '../application/SearchDocumentation';
import SearchSpareParts from '../application/SearchSpareParts';
import Carousel from '../common/Carousel';
import ContentContainer from '../common/ContentContainer';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Grid from '../common/Grid';
import Headline from '../common/Headline';
import { IconType } from '../common/Icon';
import KeyVisual from '../common/KeyVisual';
import Section, { SectionColor } from '../common/Section';

const App = () => {
    const [muted, setMuted] = useState(true);
    const [playing, setPlaying] = useState(true);
    const language = useAppSelector(state => state.translation.language);
    const translate = useTranslate();
    const localizedLink = useLocalizedLink();
    const environmentVariable = useEnvironmentVariable();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <KeyVisual height={360} url={keyVisual}>
                <Flex direction={FlexDirection.Column} justification={FlexJustification.Center}>
                    <ContentContainer>
                        <Headline label={translate(TRANSLATIONS.main.simoticsLvMotors)} line1={translate(TRANSLATIONS.main.toolsAndServices)} />
                    </ContentContainer>
                </Flex>
            </KeyVisual>
            <ContentContainer style={{ paddingTop: 50, paddingBottom: 50 }}>
                <Grid columns={4} columnsBig={2} columnsSmall={1} columnGap={30} rowGap={30} >
                    <Card
                        title={translate(TRANSLATIONS.main.selectMotor.title)}
                        linkText={translate(TRANSLATIONS.main.selectMotor.description)}
                        image={simpleSelectorCut}
                        overlay={motorSlider}
                        eventName='SelectSimpleMotor'
                        linkUrl={environmentVariable(process.env.REACT_APP_MOTOR_SELECTOR_URL)}
                    />
                    <Card
                        title={translate(TRANSLATIONS.main.configureMotor.title)}
                        linkText={translate(TRANSLATIONS.main.configureMotor.description)}
                        image={dtkSelector}
                        overlay={motorCheckboxes}
                        eventName='SelectDtkMotor'
                        linkUrl={localizedLink(process.env.REACT_APP_CONFIGURE_MOTOR_URL)}
                    />
                    <SearchDocumentation />
                    <Card
                        title={translate(TRANSLATIONS.main.IE4.title)}
                        linkText={translate(TRANSLATIONS.main.IE4.description)}
                        image={IE4}
                        eventName='IE4'
                        linkUrl={environmentVariable(process.env.REACT_APP_READY_FOR_IE4_URL)}
                        type={CardType.Wide}
                    />
                    <Card
                        title={translate(TRANSLATIONS.main.sinasave.title)}
                        linkText={translate(TRANSLATIONS.main.sinasave.description)}
                        image={sinasave}
                        eventName='SinaSave'
                        linkUrl={environmentVariable(process.env.REACT_APP_SINASAVE_URL)}
                        type={CardType.Wide}
                    />
                    <Card
                        title={translate(TRANSLATIONS.main.meps.title)}
                        linkText={translate(TRANSLATIONS.main.meps.description)}
                        image={meps}
                        eventName='MEPS'
                        linkUrl={localizedLink(process.env.REACT_APP_MEPS_URL)}
                        type={CardType.Wide}
                    />
                    <Card
                        title={translate(TRANSLATIONS.main.sizer.title)}
                        linkText={translate(TRANSLATIONS.main.sizer.description)}
                        image={sizer}
                        eventName='Sizer'
                        linkUrl={environmentVariable(process.env.REACT_APP_TST_URL)}
                        type={CardType.Wide}
                    />
                    <Card
                        title={translate(TRANSLATIONS.main.industryMall.title)}
                        linkText={translate(TRANSLATIONS.main.industryMall.description)}
                        image={industryMall}
                        icon={IconType.Shoppingcart}
                        eventName='CallMall'
                        linkUrl={localizedLink(process.env.REACT_APP_INDUSTRY_MALL_URL)}
                    />
                    <Card
                        title={translate(TRANSLATIONS.main.digitalMotor.title)}
                        linkText={translate(TRANSLATIONS.main.digitalMotor.description)}
                        image={digitalMotor}
                        eventName='DigitalMotor'
                        linkUrl={localizedLink(process.env.REACT_APP_DIGITAL_MOTOR_URL)}
                    />
                    <SearchCertificate />
                    <SearchSpareParts />
                    <Card
                        title={translate(TRANSLATIONS.main.passionForMotors.title)}
                        linkText={translate(TRANSLATIONS.main.passionForMotors.description)}
                        image={passionForMotors}
                        eventName='PassionForMotors'
                        linkUrl={environmentVariable(process.env.REACT_APP_PASSION_FOR_MOTORS)}
                        type={CardType.Wide}
                    />
                    <FindContact />
                </Grid>
            </ContentContainer>
            <Section color={SectionColor.DeepkBlue}>
                <ContentContainer>
                    <Card
                        title={translate(TRANSLATIONS.main.simolog.title)}
                        descriptions={[translate(TRANSLATIONS.main.simolog.description1), translate(TRANSLATIONS.main.simolog.description2)]}
                        linkText={translate(TRANSLATIONS.main.simolog.button)}
                        image={simolog}
                        linkUrl={localizedLink(process.env.REACT_APP_SIMOLOG_URL)}
                        type={CardType.FullWidth}
                    />
                </ContentContainer>
            </Section>
            <Carousel slidesToShow={1} beforeChange={(_, i) => setPlaying(i === 0)}>
                <div onClick={() => setMuted(x => !x)}>
                    <ReactPlayer url={video} playing={playing} muted={muted} volume={0.05} height='100%' width='100%' loop />
                </div>
                <img src={language === Language.De ? overviewDe : overviewEn} />
                <img src={language === Language.De ? technicalDataDe : technicalDataEn} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.Center}>
                    <embed src={`simotics/simotics-${language === Language.De ? 'de' : 'en'}.htm`} style={{ height: 450, width: 800, paddingTop: 30 }} />
                </Flex>
            </Carousel>
        </>
    );
};

export default App;
