import React, { useState } from 'react';
import { TRANSLATIONS } from '../../constants';
import { useTranslate } from '../../hooks/common';
import { MlfwServiceResponse } from '../../models';
import { Api } from '../../services';
import { mlfbResponse } from '../../types';
import TextInput from '../common/TextInput';

interface MlfbInputProps {
    onValueChange(mlfb: string, options: string, isValid: boolean): void;
    onLoadStart?: () => void
    onLoadEnd?: () => void
}

interface initialFeedbackState {
    translationId: string;
    index: string;
    character: string;
    errorMessage: string;
}

const MlfbInput = ({ onValueChange, onLoadStart, onLoadEnd }: MlfbInputProps) => {
    const feedbackState: initialFeedbackState = { character: '', index: '', translationId: '', errorMessage: '' };
    const optionCodesPattern = new RegExp(/^ *(([A-Z]\d[A-Z]|[A-Z]\d{2})\+)*([A-Z]\d[A-Z]|[A-Z]\d{2}) *$/);
    const baseMlfbRegExps = ['^\\d', '[A-Z]', '[A-Z]', '\\d', '\\d', '\\d', '\\d', '-?', '\\d', '[A-Z]', '[A-Z]', '\\d', '\\d', '-?', '\\d', '[A-Z]', '[A-Z]', '\\d'];
    const mlfbRequiredCharactersCount = 16;
    const [input, setInput] = useState('');
    const [feedback, setFeedback] = useState(feedbackState);
    const translate = useTranslate();

    const handleInputChange = async (value: string) => {
        const inputValue = value.toUpperCase();

        if (inputValue.length > 4) {
            validateInput(inputValue);
        } else {
            setFeedback(feedbackState);
        }

        setInput(inputValue);
    }

    const validateInput = async (inputValue: string) => {
        const mlfb = getMlfbFromInput(inputValue);
        const optionCodes = inputValue.substring(mlfb.length);
        const mlfbDone = mlfb.length >= mlfbRequiredCharactersCount + (mlfb.split('-').length - 1);
        const endsWithMlfbPostfix = mlfbDone && mlfb.endsWith('-Z');
        const mlfbValid = validateMlfb(mlfb, mlfbDone, endsWithMlfbPostfix);
        const optionCodesValid = mlfbValid && (!optionCodes.trim() || validateOptionCodes(optionCodes, endsWithMlfbPostfix));

        if (onLoadStart) {
            onLoadStart();
        }

        const mlfbValidationResult: MlfwServiceResponse = (await Api.validateMlfbWithOptions(inputValue)).data;

        if (onLoadEnd) {
            onLoadEnd();
        }

        const isResultValid = mlfbValidationResult.isServiceAvailable && mlfbValidationResult.mlfbList.length && mlfbValidationResult.mlfbList.length > 0;
        const hasError = isResultValid && mlfbValidationResult.mlfbList[0].errors.length && mlfbValidationResult.mlfbList[0].errors.length > 0;

        if (mlfbValid && optionCodesValid) {
            if (mlfbDone) {
                if (isResultValid && mlfbValidationResult.mlfbList[0].existent) {
                    setFeedback(feedbackState);
                } else {
                    const additionalErrorMessage = hasError ? mlfbValidationResult.mlfbList[0].errors[0].reason : '';

                    addErrorFeedback('main.mlfbInput.notValidMlfbWarning', feedbackState.index, feedbackState.character, additionalErrorMessage);
                }
            } else {
                addErrorFeedback('main.mlfbInput.notCompleteWarning', feedbackState.index, feedbackState.character);
            }
        }

        let inputValid = mlfbValid && optionCodesValid && mlfbDone && !hasError;

        onValueChange(mlfb, optionCodes.trim(), inputValid);

        return inputValid;
    }

    const getMlfbFromInput = (inputValue: string) => {
        const mlfb = [inputValue.substring(0, mlfbRequiredCharactersCount)];

        let hyphenCount = mlfb[0].split('-').length - 1;

        for (let i = mlfb[0].length; i < mlfb[0].length + hyphenCount; i++) {
            if (inputValue[i]) {
                mlfb.push(inputValue[i]);
                if (inputValue[i] === '-') {
                    hyphenCount++;
                }
            }
        }

        let index = mlfb.join('').length;

        if (inputValue[index] === '-') {
            mlfb.push(inputValue[index]);
            if (inputValue[++index] === 'Z') {
                mlfb.push(inputValue[index]);
            }
        }

        return mlfb.join('');
    }

    const validateMlfb = (mlfb: string, mlfbDone: boolean, endsWithMlfbPostfix: boolean) => {
        const mlfbRegExps = [...baseMlfbRegExps];

        let mlfbValid: boolean;
        let regExp = new RegExp('');
        let index = 0;
        let characterIndex = 0;

        if (mlfbDone && mlfb.endsWith('-')) {
            addErrorFeedback('main.mlfbInput.orderNumberEndsHyphenError', (mlfb.lastIndexOf('-') + 1).toString(), feedbackState.character);
            return false;
        }

        if (endsWithMlfbPostfix) {
            mlfbRegExps.push('-Z');
        }


        do {
            regExp = new RegExp(regExp.source.concat(mlfbRegExps[index]));

            const match = regExp.exec(mlfb);

            mlfbValid = match !== null;
            characterIndex = mlfbValid ? match![0].length : characterIndex + 1;
        } while (mlfbValid && mlfb[characterIndex] && index++ < mlfbRegExps.length);

        if (!mlfbValid) {
            addErrorFeedback('main.mlfbInput.orderNumberSyntaxError', characterIndex.toString(), mlfb[characterIndex - 1]);
        }

        return mlfbValid;
    }

    const validateOptionCodes = (optionCodes: string, endsWithMlfbPostfix: boolean) => {
        const optionCodesValid = optionCodesPattern.test(optionCodes);

        if (!endsWithMlfbPostfix && !optionCodes.startsWith(' ')) {
            addErrorFeedback('main.mlfbInput.orderNumberSyntaxError', feedbackState.index, feedbackState.character);

            return false;
        }

        if (!optionCodesValid) {
            addErrorFeedback('main.mlfbInput.optionCodesSyntaxError', feedbackState.index, feedbackState.character);
        }

        return optionCodesValid;
    }

    const addErrorFeedback = (translationId: string, index: string, character: string, errorMessage = '') => {
        setFeedback({
            translationId: translationId,
            index: index,
            character: character,
            errorMessage: errorMessage
        });
    }

    const getTranslation = (): string => {
        const transaction: mlfbResponse = feedback.translationId.split('.').slice(-1).toString() as mlfbResponse;

        return translate(TRANSLATIONS['main']['mlfbInput'][transaction])
            .replace('{index}', feedback.index)
            .replace('{character}', feedback.character)
            .replace('{errorMessage}', feedback.errorMessage)
    }

    const errorMessage = feedback.translationId ? getTranslation() : '';

    return (
        <TextInput label='MLFB' name='mlfb' value={input} placeholder='1LE10010EA422AA4-Z B02+G40' onChange={handleInputChange} getErrorMessage={() => errorMessage} />
    );
}

export default MlfbInput;
