import React, { ReactNode } from 'react';
import Slider from 'react-slick';

interface CarouselProps {
    slidesToShow: number;
    beforeChange?: (currentSlide: number, nextSlide: number) => void;
    children?: ReactNode;
}

const Carousel = ({ beforeChange, children, slidesToShow }: CarouselProps) => (
    <div className="carousel">
        <Slider slidesToShow={slidesToShow} slidesToScroll={1} speed={500} infinite={false} beforeChange={beforeChange} arrows dots>
            {children}
        </Slider>
    </div>
);

export default Carousel;
