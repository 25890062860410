import React, { CSSProperties, ReactNode } from 'react';

interface ContentContainerProps {
    children?: ReactNode;
    header?: boolean;
    style?: CSSProperties;
}

const ContentContainer = ({ children, header, style }: ContentContainerProps) => (
    <div className={`content-container ${header ? 'header-content-container' : ''}`} style={style}>
        {children}
    </div>
);

export default ContentContainer;
