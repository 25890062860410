import React from 'react';
import { Routes, Route } from 'react-router-dom';
import App from './ts/components/application/App';

const routes = (
    <Routes>
        <Route path='/*' element={<App />} />
    </Routes>
);

export default routes;
