import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import routes from './routes';
import 'rc-slider/assets/index.css';
import './css/styles.css';
import { createBrowserHistory } from 'history';
import { getEnvironment } from './ts/services';
import Layout from './ts/components/layout/Layout';
import { store } from './ts/store/store';
import 'rc-slider/assets/index.css';
import './css/styles.css';

getEnvironment();

// Create browser history to use in the Redux store
const history = createBrowserHistory({});
const languages = ['en', 'de', 'fr', 'it', 'fi'];

const root = createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Layout>
                {routes}
            </Layout>
        </BrowserRouter>
    </Provider>
);
