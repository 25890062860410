import React, { useState } from 'react';
import { trackEvent } from '../../services';
import Button, { ButtonType } from '../common/Button';
import Flex, { FlexDirection, FlexJustification } from '../common/Flex';
import Icon, { IconType } from '../common/Icon';
import MlfbInput from '../common/MlfbInput';
import SpacingConainer from '../common/SpacingContainer';
import TextInput from '../common/TextInput';
import Tooltip from '../common/Tooltip';
import CustomCard from './CustomCard';
import { useTranslate } from '../../hooks/common';
import { TRANSLATIONS } from '../../constants/Translations';

const SearchSpareParts = () => {
    const [mlfb, setMlfb] = useState({ mlfb: '', options: '', isValid: false });
    const [isMlfbInputValidating, setIsMlfbInputValidating] = useState(false);
    const [serialNumber, setSerialNumber] = useState({ text: '', isValid: false });
    const valid = mlfb.isValid && serialNumber.isValid;
    const translate = useTranslate();

    const setMlfbValue = (mlfb: string, options: string, isValid: boolean) => setMlfb({ mlfb, options, isValid });

    const setSerialNumberValue = (text: string) => setSerialNumber({ text, isValid: text.length > 0 });

    const handleButtonClick = () => {
        const url = `${process.env.REACT_APP_SEARCH_SPARE_PARTS_URL}${mlfb.mlfb}+${encodeURIComponent(mlfb.options)}&sn=${serialNumber.text}`;

        window.open(url, '_blank');
        trackEvent('SearchSpareParts');
    };

    const handleInformationClick = () => {
        window.open(process.env.REACT_APP_INFORMATION_URL, '_blank');
    };

    return (
        <CustomCard title={translate(TRANSLATIONS.main.searchSpareParts.title)} description={translate(TRANSLATIONS.main.searchSpareParts.description)} icon={IconType.Settings}>
            <SpacingConainer>
                <MlfbInput onValueChange={setMlfbValue} onLoadStart={() => setIsMlfbInputValidating(true)} onLoadEnd={() => setIsMlfbInputValidating(false)} />
                <TextInput label={translate(TRANSLATIONS.main.searchSpareParts.serialNumber)} name="serialNumber" value={serialNumber.text} placeholder="123456701001" onChange={setSerialNumberValue} />
                <Flex direction={FlexDirection.Row} justification={FlexJustification.FlexEnd} gap={10}>
                    <Tooltip id="searchSparePartsInformation" text={translate(TRANSLATIONS.main.searchSpareParts.informationTooltip)} place="bottom">
                        <Button type={ButtonType.Secondary} onClick={handleInformationClick}>
                            <Icon type={IconType.Information} />
                        </Button>
                    </Tooltip>
                    <Tooltip id="searchSparePartsButton" text={translate(TRANSLATIONS.main.searchSpareParts.buttonTooltip)} hidden={valid} place="bottom">
                        <Button type={ButtonType.Secondary} disabled={!valid || isMlfbInputValidating} onClick={handleButtonClick}>
                            <Icon type={isMlfbInputValidating ? IconType.Loader : IconType.Linkextern} />
                            {translate(TRANSLATIONS.main.searchSpareParts.search)}
                        </Button>
                    </Tooltip>
                </Flex>
            </SpacingConainer>
        </CustomCard>
    );
};

export default SearchSpareParts;
